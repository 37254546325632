<template>
  <div class="content">
    <div class="md-layout md-alignment-center-center">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon v-if="!load">insert_invitation</md-icon>
              <i class="md-icon md-icon-font md-theme-default" v-if="load">
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </i>
            </div>
            <h4 class="title">Dados já carregados Brasil.io</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-alignment-center-center">
              <div class="md-layout-item md-size-90">
                <YearCalendar
                  v-model="year"
                  :activeDates.sync="activeDates"
                  @toggleDate="toggleDate"
                  @input="toggleYear"
                  lang="pt"
                  :showYearSelector="showYearSelector"
                  :prefixClass="prefixClass"
                ></YearCalendar>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import YearCalendar from "vue-material-year-calendar";
import Vue from "vue";

export default {
  created() {
    this.source = this.$route.params.source;
    this.type = this.$route.params.type;
  },
  mounted() {
    this.getDataSet();
  },
  components: { YearCalendar },
  data() {
    return {
      type: String,
      source: String,
      year: 2020,
      activeDates: [],
      dataSet: Object,
      prefixClass: "md-date",
      showYearSelector: true,
      url: `v1/dataset/`,
      load: false
    };
  },
  methods: {
    async toggleDate(dateInfo) {
      this.load = true;
      try {
        await Vue.$http.patch(
          `v1/dataset/${this.source}/${this.type}/${this.dataSet.id}`,
          {
            date: dateInfo.date
          },
          {
            timeout: 9999999
          }
        );
        this.$store.dispatch("alerts/success", `Dados do dia ${dateInfo.date}`);
        this.getDataSet();
        this.load = false;
      } catch (error) {
        this.load = false;
        console.log(error);
        this.load = false;
        return await error.response;
      }
    },
    toggleYear(year) {
      this.year = year;
      this.getDataSet();
    },
    async getDataSet() {
      try {
        let response = await Vue.$http.get(
          `${this.url}${this.source}/${this.type}?dates_load_data=${this.year}`,
          null,
          {
            headers: {
              "Access-Control-Allow-Origin": "*"
            },
            timeout: 300000
          }
        );
        this.dataSet = response.data;
        this.activeDates = response.data.dates;
      } catch (error) {
        this.activeDates = [];
        error.toString();

        return await error.response.data;
      }
    }
  }
};
</script>
<style lang="stylus">
.md-date
  background-color: #8bc34a
  color: white
  &.red
    background-color: #a00
    color: white
  &.blue
    background-color: #0000aa
    color: white
  &.your_customized_classname
    background-color: yellow
    color: black
</style>
